import { AttachmentType, ContentFilter } from "@dashboard-v3/api";
import { typePath } from "../CreateOrEdit/context/helpers";
import { State } from "../CreateOrEdit/context/types";

export const attachmentSizeOpts = [
  { key: crypto.randomUUID(), value: "BYTE", text: "Bytes" },
  { key: crypto.randomUUID(), value: "KILOBYTE", text: "Kilobytes" },
  { key: crypto.randomUUID(), value: "MEGABYTE", text: "Megabytes" },
];

export const getContentFilterDefaults = (): ContentFilter => ({
  attachmentSize: null,
  includeAttachmentExtensions: [],
  excludeAttachmentExtensions: [],
  includeAttachmentNames: [],
  excludeAttachmentNames: [],
  includeAttachmentTypes: [] as AttachmentType[],
  excludeAttachmentTypes: [] as AttachmentType[],
  excludeInlineImages: true,
  includeZipWithPassword: false,
  includeRfc822: true,
});

export const getContentFilter = (state: State) => {
  if (state.type === "BLOCK_ATTACHMENTS" || state.type === "REQUEST_APPROVAL") {
    return typePath(state.type, state.rule).contentFilter;
  }
  return typePath(state.type, state.rule)?.contentToSave?.filter;
};
