import produce, { Draft } from "immer";
import { ActionState } from "pages/Rules/RuleActions/types";
import {
  canImpersonate,
  getStorage,
  shouldResetFolderLocation,
} from "pages/Rules/CloudStorage/utils";
import { canUseStoreByUserAccount } from "pages/Rules/CloudStorage/AdvancedOptions/UseStoreByUserAccount";
import { isBlank } from "utils/string";
import { isEmptyArray } from "utils/isEmptyArray";
import validateStep from "./validateStep";
import {
  typePath,
  updateArchivingAddress,
  updateCloudStorage,
  updateEmailFlow,
  updateMailbox,
  updateApprovalSteps,
  updateWebhook,
  getMailbox,
  isCopyFilesAllowed,
} from "../helpers";
import { isOfficeIndividual } from "../../Steps/Target/Mailbox/utils";

import {
  EmailFlow,
  Storage,
  ArchivingAddress,
  Mailbox,
  StorageWebhook,
  ProviderAccounts,
  ApprovalSteps,
  Content,
} from "@dashboard-v3/api";
import { State } from "../types";

export function updateStep(state: State, payload: UpdateStep): State {
  if (payload.step === "action") {
    return produce(state, draft => {
      const ruleTypePath = typePath(draft.type, draft.rule);

      draft.steps.action.done = getActionStatus(draft, payload);

      if ("contentToSave" in ruleTypePath) {
        ruleTypePath.contentToSave.content = payload.changes.content;
        resetCopyFilesSetting(draft, payload.changes.content);
      }

      if ("trash" in ruleTypePath) {
        ruleTypePath.trash = payload.changes.trash;
      }

      if ("linksFormat" in ruleTypePath) {
        ruleTypePath.linksFormat = payload.changes.linksFormat;
      }

      validateStep("action", draft);
    });
  }

  if (payload.step === "target") {
    return produce(state, draft => {
      draft.steps.target.done = false;
      const target = state.rule.targetType;

      if (target === "ARCHIVING_ADDRESS") {
        updateArchivingAddress(
          draft.rule,
          payload.changes as Partial<ArchivingAddress>
        );

        if ("emailAddress" in payload.changes) {
          const sameOriginalEmail =
            draft.checks.archivingAddress.initialAddress ===
            payload.changes.emailAddress;

          draft.checks.archivingAddress.isValidEmail = sameOriginalEmail;
        }
      }

      if (target === "EMAIL_FLOW") {
        updateEmailFlow(
          draft.rule,
          payload.changes as
            | Partial<EmailFlow>
            | { saveForwardedMessages: boolean }
        );
      }

      if (target === "MAIL_BOX") {
        const mailbox = updateMailbox(
          draft.rule,
          payload.changes as Partial<Mailbox>
        );

        if (mailbox.provider !== "IMAP") {
          delete mailbox.imapServer;
        }

        if (isOfficeIndividual(mailbox)) {
          const changes = payload.changes as Partial<Mailbox>;
          const alreadySetLoginUser = Boolean(mailbox.loginUser);
          if (hasOneAccount(changes.accounts) && !alreadySetLoginUser) {
            mailbox.loginUser = changes.accounts.entities[0];
          }
        } else {
          delete mailbox.loginUser;
        }
      }

      if (target === "STORAGE_WEBHOOK") {
        updateWebhook(draft, payload.changes as Partial<StorageWebhookUpdate>);
      }

      validateStep("target", draft);
    });
  }

  if (payload.step === "cloudStorage") {
    return produce(state, draft => {
      draft.steps.cloudStorage.done = false;
      const storage = updateCloudStorage(draft.rule, payload.changes);

      if (shouldResetFolderLocation(storage)) {
        delete storage.location.parentId;
        delete storage.location?.parentName;
      }

      if (!canImpersonate(storage)) {
        draft.storageManagement = undefined;
      }

      if (!canUseStoreByUserAccount(draft)) {
        storage.allManagedUserIds = false;
      }

      const nullableFields = [
        "userId",
        "storeId",
        "storeIdUserId",
        "asUser",
        "storageId",
        "metadata",
        "linkSecurity",
        "locale",
        "timeZone",
        "asUsers",
        "folderSharing",
      ];

      nullableFields.forEach(fieldName => {
        if (isBlank(storage[fieldName]) || isEmptyArray(storage[fieldName])) {
          delete storage[fieldName];
        }
      });

      validateStep("cloudStorage", draft);
    });
  }

  if (payload.step === "filePath") {
    return produce(state, draft => {
      draft.steps.storageFilepath.done = false;
      const storage = updateFilepath(draft, payload.changes);

      // Remove properties with null values
      Object.getOwnPropertyNames(storage.location).forEach(fieldName => {
        if (isBlank(storage.location[fieldName])) {
          delete storage.location[fieldName];
        }
      });

      validateStep("storageFilepath", draft);
    });
  }

  if (payload.step === "approvalSteps") {
    return produce(state, draft => {
      draft.steps.approvalSteps.done = false;
      updateApprovalSteps(draft.rule, payload.changes);
      validateStep("approvalSteps", draft);
    });
  }

  return state;
}

function updateFilepath(draft: Draft<State>, changes: Partial<Storage>) {
  draft.steps.storageFilepath.done = false;
  const storage = getStorage(draft.rule);
  if ("location" in storage) {
    storage.location = {
      ...storage.location,
      ...changes.location,
    };
  }

  if ("collaboration" in changes) {
    if (!changes.collaboration) {
      delete storage.collaboration;
    } else {
      storage.collaboration = {
        ...storage.collaboration,
        ...changes.collaboration,
      };
    }
  }

  if ("override" in changes) {
    storage.override = changes.override;
  }

  return storage;
}

export type Action = {
  type: "updateStep";
  payload: UpdateStep;
};

export type StorageWebhookUpdate = StorageWebhook & {
  webhookStatus: State["webhookStatus"];
};

type UpdateStep =
  | {
      step: "action";
      changes: ActionState;
    }
  | {
      step: "target";
      changes:
        | Partial<EmailFlow | ArchivingAddress | Mailbox | StorageWebhookUpdate>
        | { saveForwardedMessages: boolean };
    }
  | {
      step: "cloudStorage";
      changes: Partial<Storage>;
    }
  | {
      step: "filePath";
      changes: Partial<Storage>;
    }
  | {
      step: "approvalSteps";
      changes: Partial<ApprovalSteps>;
    };

const getActionStatus = (state: State, payload: UpdateStep) => {
  const path = typePath(state.type, state.rule);
  const { changes } = payload;

  if ("linksFormat" in changes || "trash" in changes) {
    return false;
  }

  if ("contentToSave" in path && "content" in changes) {
    const { content: current } = path.contentToSave;
    const { content: update } = changes;
    const currentTypes = current.map(c => c.type).join("");
    const newTypes = update.map(c => c.type).join("");
    const isTypeChange = currentTypes !== newTypes;

    if (!isTypeChange) {
      state.steps.storageFilepath.done = false;
      state.steps.storageFilepath.valid = update.every(
        item => !isBlank(item.nameFormat)
      );
    }
    return !isTypeChange;
  }
};

function hasOneAccount(accounts: ProviderAccounts) {
  return accounts.type === "ACCOUNT" && accounts.entities.length === 1;
}

function resetCopyFilesSetting(state: State, content: Content[]) {
  const mailbox = getMailbox(state.rule);

  if (!mailbox) return;

  if (isCopyFilesAllowed(content, state.rule.actionType)) return;

  delete mailbox.copyEmailFilesWhenNoAttachments;
  updateMailbox(state.rule, mailbox);
}
