import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "@material-ui/core";
import downloadJson from "utils/downloadFile";
import DetailItem from "./DetailItem";

import { TracingEvent, TracingEventDetails } from "@dashboard-v3/api";

interface DetailsProps {
  details: TracingEventDetails[];
  includeAll?: boolean;
}

export default function Details({ details, includeAll }: DetailsProps) {
  const { t } = useTranslation("components");
  const { emailData, msgUID } = getEmailData(details);

  const onEventDownload = () => {
    downloadJson(details, t("emailEventDownloadName", { msgUID }));
  };

  return (
    <Grid container spacing={2}>
      {details.map(event => {
        const { action, data } = event;

        if (action === "mail_received" && data.preprocess) return null;

        return (
          <DetailItem
            key={event.eventId}
            event={event}
            recipient={emailData?.recipient}
            includeAll={includeAll}
          />
        );
      })}

      <Grid item xs={12}>
        <Box mt={2}>
          <Button onClick={onEventDownload} color="primary" variant="outlined">
            {t("common:download")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export function getEmailData(itemDetails: TracingEventDetails[]) {
  const mailReceived = itemDetails.find(e => e.action === "mail_received");
  if (!mailReceived) return { emailData: null, msgUID: null };
  const { data, msgUID } = mailReceived as TracingEvent;
  return { emailData: data, msgUID };
}
