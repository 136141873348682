import { Box, Button, MenuItem, Select } from "@material-ui/core";
import useSWR from "swr";
import { fetcher } from "utils/apiRequestWithErrorCode";
import { FilterPolicy } from "@dashboard-v3/api";
import { useRuleContext } from "../../context/ruleContext";
import SwitchField from "components/Forms/SwitchField";
import { useTranslation } from "react-i18next";

export default function FilterPolicyOption({
  readOnly = false,
}: {
  readOnly?: boolean;
}) {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();

  const { data: filterOptions, isLoading } = useSWR<FilterPolicy[]>(
    "/filter-policies",
    fetcher
  );

  const selection = state.rule.filterPolicyId;

  function setFilterPolicy(filterPolicyId: string) {
    dispatch({
      type: "updateFilterPolicy",
      payload: { filterPolicyId },
    });
  }

  const noFilterOptions = filterOptions?.length === 0;

  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <SwitchField
          name="filterPolicyEnabled"
          label={t("filterPolicy.toggle")}
          description={t("filterPolicy.help")}
          value={Boolean(selection)}
          onChange={event => {
            const { checked } = event.target;
            setFilterPolicy(checked ? filterOptions[0].id : "");
          }}
          disabled={isLoading || noFilterOptions || readOnly}
        />
        {noFilterOptions && (
          <Box ml={2}>
            <Button
              color="primary"
              href="/filter-policies/new"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("filterPolicy.create")}
            </Button>
          </Box>
        )}
      </Box>
      {filterOptions && Boolean(selection) && (
        <Box mb={3} mt={2}>
          <SelectFilterPolicy
            value={selection}
            onChange={setFilterPolicy}
            options={filterOptions}
            disabled={readOnly}
          />
        </Box>
      )}
    </Box>
  );
}

function SelectFilterPolicy({
  value,
  onChange,
  options,
  disabled = false,
}: {
  value: FilterPolicy["id"] | "";
  onChange: (value: FilterPolicy["id"] | "") => void;
  options: FilterPolicy[];
  disabled?: boolean;
}) {
  return (
    <Select
      variant="outlined"
      value={value}
      disabled={disabled}
      onChange={event => {
        onChange(event.target.value as string);
      }}
    >
      {options.map(p => (
        <MenuItem key={p.id} value={p.id}>
          {p.name}
        </MenuItem>
      ))}
    </Select>
  );
}
