/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import { ShowMoreBtn } from "components/List";
import SectionHeader from "components/SectionHeader";
import FiltersForm from "./FiltersForm";
import ReportsList from "./ReportsList";
import ResultList from "./ResultsList";
import {
  createTracingReport,
  getAllTracingReports,
  getTracingReportById,
  search,
  cancelReport,
} from "./api";
import {
  TracingEvent,
  TracingReport,
  TracingReportsResponse,
  TracingSearchRequest,
} from "@dashboard-v3/api";

export enum Status {
  Idle = "IDLE",
  Loading = "LOADING",
  Searching = "SEARCHING",
  FetchingMoreResults = "FETCHING_MORE_RESULTS",
  FetchingReports = "FETCHING_REPORTS",
  GeneratingReport = "GENERATING_REPORT",
}

type SectionControl = {
  reports: boolean;
  search: boolean;
};

export default function Tracing() {
  const { t } = useTranslation("tracing");
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<Status>(Status.Idle);
  const [control, setControl] = useState<SectionControl>(initCtrl);
  const [searchCriteria, setSearchCriteria] = useState<TracingSearchRequest>();
  const [searchResults, setSearchResults] = useState<TracingEvent[]>(null);
  const [hasMore, setHasMore] = useState(false);
  const [canCreateReport, setCanCreateReport] = useState(true);
  const [reportsData, setReportsData] =
    useState<TracingReportsResponse>(initReports);

  useEffect(() => {
    async function initLoad() {
      setStatus(Status.Loading);
      try {
        const res = await getAllTracingReports(5, 1);
        setReportsData(res);
        handlePendingReports(res.reports);
      } catch (e) {
        enqueueSnackbar(t("error.fetchTracingReports"), {
          variant: "error",
        });
      } finally {
        setStatus(Status.Idle);
      }
    }

    if (!reportsData.reports.length) {
      initLoad();
    }
  }, []);

  async function makeSearch(criteria: TracingSearchRequest) {
    try {
      const results = await search(criteria);
      setHasMore(results.length === LIST_LIMIT);
      setSearchResults(previous => [...previous, ...results]);
    } catch (error: unknown) {
      console.error(error);
      enqueueSnackbar(t("error.fetchSearchResults"), {
        variant: "error",
      });
      setSearchResults([]);
    } finally {
      setStatus(Status.Idle);
    }
  }

  async function newSearch(criteria: TracingSearchRequest) {
    setStatus(Status.Searching);
    const paginatedCriteria = { ...criteria, limit: LIST_LIMIT, offset: 0 };
    setSearchCriteria(paginatedCriteria);
    setSearchResults([]);
    await makeSearch(paginatedCriteria);
  }

  async function loadMore() {
    setStatus(Status.FetchingMoreResults);
    const newCriteria = {
      ...searchCriteria,
      offset: searchCriteria.offset + LIST_LIMIT,
    };
    setSearchCriteria(newCriteria);
    await makeSearch(newCriteria);
  }

  const handlePendingReports = (r: TracingReportsResponse["reports"]) => {
    const pendingReport = r.find(r => r.status === "PENDING");
    if (pendingReport) {
      checkReportStatus(pendingReport.id);
      setControl({ reports: true, search: false });
    }
  };

  function checkReportStatus(id: string) {
    let status: TracingReport["status"];
    setCanCreateReport(false);
    const interval = setInterval(async () => {
      const report = await getTracingReportById(id);
      status = report.status;
      if (status !== "PENDING") {
        setReportsData(prev => {
          const filteredList = prev.reports.filter(r => r.id !== report.id);
          return { ...prev, reports: [report, ...filteredList] };
        });
        setCanCreateReport(true);
        clearInterval(interval);
      }
    }, 2000);
  }

  async function getReportsByPage(page: number) {
    setStatus(Status.FetchingReports);
    try {
      const res = await getAllTracingReports(5, page);
      setReportsData(res);
      handlePendingReports(res.reports);
    } catch (e) {
      enqueueSnackbar(t("error.fetchTracingReports"), {
        variant: "error",
      });
    } finally {
      setStatus(Status.Idle);
    }
  }

  async function createReport(criteria: TracingSearchRequest) {
    setStatus(Status.GeneratingReport);
    try {
      await createTracingReport(criteria);
      await getReportsByPage(1);
    } catch (e) {
      enqueueSnackbar(t("downloadReportError"), { variant: "error" });
    } finally {
      setStatus(Status.Idle);
    }
  }

  const onPageChange = async (page: number) => {
    await getReportsByPage(page);
  };

  const onSectionExpand = (section: keyof SectionControl) => () => {
    setControl(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const onCancelReport = async (id: string) => {
    const report = await cancelReport(id);
    setReportsData(prev => {
      const filteredList = prev.reports.filter(r => r.id !== report.id);
      return { ...prev, reports: [report, ...filteredList] };
    });
    setCanCreateReport(true);
  };

  if (status === Status.Loading) {
    return (
      <Box data-testid="tracing-loading">
        <SectionHeader title={t("tracing")}>
          <Typography>{t("sectionExplanation")}</Typography>
        </SectionHeader>
        <Paper style={{ padding: "12px 16px" }}>
          <LinearProgress />
        </Paper>
      </Box>
    );
  }

  return (
    <>
      <SectionHeader title={t("tracing")}>
        <Typography>{t("sectionExplanation")}</Typography>
      </SectionHeader>

      {reportsData.reports.length !== 0 && (
        <ReportsList
          expanded={control.reports}
          data={reportsData}
          page={reportsData.currentPage}
          loading={status === Status.FetchingReports}
          onExpand={onSectionExpand("reports")}
          onPageChange={onPageChange}
          onCancelReport={onCancelReport}
        />
      )}

      <FiltersForm
        expanded={control.search}
        status={status}
        disableCreateReport={!canCreateReport}
        onExpand={onSectionExpand("search")}
        onSearch={newSearch}
        onCreateReport={createReport}
      />

      {status === Status.Searching && (
        <div data-testid="tracing__result-list_loading">
          <Paper style={{ padding: "12px 16px" }}>
            <LinearProgress />
          </Paper>
        </div>
      )}

      {status !== Status.Searching && (
        <>
          <ResultList results={searchResults} />
          {hasMore && (
            <Box marginTop="10px">
              <ShowMoreBtn
                loading={status === Status.FetchingMoreResults}
                onClick={loadMore}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}

const LIST_LIMIT = 100;

const initReports = (): TracingReportsResponse => ({
  reports: [],
  total: 0,
  pages: 0,
  pageSize: 0,
  currentPage: 1,
});

const initCtrl = (): SectionControl => ({
  reports: false,
  search: true,
});
