/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import {
  Box,
  Button,
  capitalize,
  Divider,
  Grid,
  GridProps,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { PropertyData } from "components/EmailEventDetails/styled";
import { getErrorCode } from "utils/apiRequestWithErrorCode";
import { getFormattedTimeStamp } from "utils/dateUtils";
import { useFetch } from "utils/api/useFetch";
import {
  getWebhookData,
  isBalancedUploadsErrorNotification,
  isEmailErrorNotification,
  isWebhookErrorNotification,
} from "./utils";

import { NotificationEvent, Rule } from "@dashboard-v3/api";

interface Props {
  notification: NotificationEvent;
  onError: () => void;
}

const RuleDetails = ({ notification, onError }: Props) => {
  const { t } = useTranslation(["notifications", "tracing", "rules"]);
  const { enqueueSnackbar } = useSnackbar();
  const { data } = notification;

  const { data: rule, isLoading } = useFetch<Rule>(`/rules/${data.ruleId}`, {
    inmutable: true,
    onError: error => {
      const erroCode = getErrorCode(error);

      if (error && erroCode !== "NOT_FOUND") {
        enqueueSnackbar(t("tracing:error.fetchItemEvents"), {
          variant: "error",
        });
        onError();
      }
    },
    onErrorRetry(error) {
      // Never retry on 404.
      if (error.status === 404) return;
    },
  });

  if (isLoading) {
    return (
      <Box mt={7}>
        <LinearProgress />
      </Box>
    );
  }

  if (!rule) {
    return (
      <PropertyData color="textPrimary">{t("rules.unavailable")}</PropertyData>
    );
  }

  const webhook = getWebhookData(rule, data.folderId);
  const showWebhookDetails = isWebhookErrorNotification(notification);
  const showAccountDetails = isEmailErrorNotification(notification);
  const showBalanceAccount = isBalancedUploadsErrorNotification(notification);

  return (
    <>
      <Divider />
      <StyledGrid container spacing={2}>
        <StyledGrid item lg={3}>
          <Typography variant="subtitle2">
            {t("rules.subtitle.name")}
          </Typography>
          <Typography variant="body2">{capitalize(rule.name)}</Typography>
        </StyledGrid>
        <StyledGrid item lg={3}>
          <Typography variant="subtitle2">
            {t("rules.subtitle.action")}
          </Typography>
          <Typography variant="body2">
            {t(`rules:actions.${rule.actionType}.title`)}
          </Typography>
        </StyledGrid>
        <StyledGrid item lg={3}>
          <Typography variant="subtitle2">
            {t("rules.subtitle.target")}
          </Typography>
          <Typography variant="body2">
            {t(`rules:${rule.targetType}`)}
          </Typography>
        </StyledGrid>
        <StyledGrid item lg={3}>
          <Typography variant="subtitle2">
            {t("rules.subtitle.updatedAt")}
          </Typography>
          <Typography variant="body2">
            {getFormattedTimeStamp(rule.updatedAt, true)}
          </Typography>
        </StyledGrid>
        {showAccountDetails && (
          <StyledGrid item lg={3}>
            <Typography variant="subtitle2">
              {t("rules.subtitle.emailAccount")}
            </Typography>
            <Typography variant="body2">{data.emailAccount}</Typography>
          </StyledGrid>
        )}
        {showBalanceAccount && (
          <StyledGrid item lg={3}>
            <Typography variant="subtitle2">
              {t("rules.subtitle.storageAccount")}
            </Typography>
            <Typography variant="body2">{data.storageUser}</Typography>
          </StyledGrid>
        )}
      </StyledGrid>
      {showWebhookDetails && (
        <StyledGrid container spacing={2}>
          <StyledGrid item lg={3}>
            <Typography display="block" variant="subtitle2">
              {t("rules.subtitle.storageAccount")}
            </Typography>
            <Typography variant="body2">{webhook.account}</Typography>
          </StyledGrid>
          <StyledGrid item lg={9}>
            <Typography display="block" variant="subtitle2">
              {t("rules.subtitle.folder")}
            </Typography>
            <FolderContainer>{webhook.folder.path}</FolderContainer>
          </StyledGrid>
        </StyledGrid>
      )}
      {rule.description && (
        <div style={{ marginTop: "12px" }}>
          <Typography display="block" variant="subtitle2">
            {t("rules.subtitle.description")}
          </Typography>
          <Typography display="block" variant="body2">
            {rule.description}
          </Typography>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <Typography color="textPrimary">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => window.open(`/rules/${data.ruleId}/edit`, "_blank")}
          >
            {t("rules.editRule")}
          </Button>
        </Typography>
      </div>
    </>
  );
};

const StyledGrid = styled(Grid)<GridProps>`
  margin-top: ${({ container }) => container && "12px"};
`;

const FolderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default RuleDetails;
