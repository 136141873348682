import { useTranslation } from "react-i18next";
import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ReviewerAutocomplete from "./ReviewerAutocomplete";
import { getApprovalSteps } from "../../../context/helpers";
import { useRuleContext } from "../../../context/ruleContext";

import { Supervisor } from "@dashboard-v3/api";

export default function StepsList() {
  const { t } = useTranslation("rules");
  const classes = useStyles();
  const { state, dispatch } = useRuleContext();
  const { supervisors } = getApprovalSteps(state.rule);
  const canAddReviewer = supervisors.length < 10;

  function updateSupervisors(supervisors: Supervisor[]) {
    dispatch({
      type: "updateStep",
      payload: { step: "approvalSteps", changes: { supervisors } },
    });
  }

  function addReviewer() {
    if (!canAddReviewer) return;

    updateSupervisors([...supervisors, { entity: null, type: null }]);
  }

  function removeReviewer(index: number) {
    const update = [...supervisors];
    update.splice(index, 1);

    updateSupervisors(update);
  }

  function getStepTranslation(
    partial: "stepTitle" | "stepDesc",
    supervisor: Supervisor
  ) {
    const { type } = supervisor;

    if (!type) return t(`approvalSteps.list.${partial}.default`);

    return t(`approvalSteps.list.${partial}.${type}`);
  }

  return (
    <div className={classes.listContainer}>
      <div className={classes.listHeaderContainer}>
        <div className={classes.headerTitleContainer}>
          <Typography
            className={classes.headerTitle}
            variant="subtitle1"
            color="textPrimary"
          >
            {t("approvalSteps.list.title")}
          </Typography>
          <Typography
            className={classes.headerSubtitle}
            variant="caption"
            color="textSecondary"
          >
            {t("approvalSteps.list.desc")}
          </Typography>
        </div>
      </div>
      <Stepper className={classes.stepper} orientation="vertical" nonLinear>
        {supervisors.map((supervisor, i) => {
          return (
            <Step
              key={i}
              expanded={true}
              active={!!supervisor?.entity}
              style={{
                marginBottom: isLastStep(supervisors, i) ? "70px" : "initial",
              }}
            >
              <StepLabel className={classes.stepLabel}>
                <div style={{ marginLeft: 8 }}>
                  <Typography variant="subtitle2">
                    {getStepTranslation("stepTitle", supervisor)}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {getStepTranslation("stepDesc", supervisor)}
                  </Typography>
                </div>
                {isLastStep(supervisors, i) && (
                  <div
                    className={classes.addBtnContainer}
                    onClick={addReviewer}
                  >
                    <div className={classes.addBtnSeparator} />
                    <AddCircleIcon
                      className={classes.addBtn}
                      color={canAddReviewer ? "primary" : "disabled"}
                    />
                    <Typography
                      className={classes.addBtnLabel}
                      variant="caption"
                      color={canAddReviewer ? "primary" : "textSecondary"}
                    >
                      {t("approvalSteps.addReviewerBtn")}
                    </Typography>
                  </div>
                )}
              </StepLabel>
              <StepContent className={classes.stepContent}>
                <ReviewerAutocomplete
                  index={i}
                  value={supervisor}
                  onChange={updateSupervisors}
                  onRemoveReviewer={() => removeReviewer(i)}
                />
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

const isLastStep = (options: Supervisor[], index: number) => {
  return options.length - 1 === index;
};

const useStyles = makeStyles({
  listContainer: { display: "flex", flexDirection: "column", gap: 10 },
  listHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitleContainer: {
    maxWidth: "85%",
    display: "flex",
    flexDirection: "column",
  },
  headerTitle: { fontWeight: 500 },
  headerSubtitle: { marginBottom: 15 },
  stepper: {
    padding: 0,
    "& .MuiStepConnector-root.MuiStepConnector-vertical": {
      padding: 0,
    },
  },
  stepLabel: { position: "relative", width: "90%" },
  stepContent: { marginTop: 0 },
  addBtnContainer: { position: "absolute", left: "-1.8px" },
  addBtnSeparator: {
    height: "95px",
    width: "50%",
    marginTop: "3px",
    marginBottom: "10px",
    borderRight: "1px dashed #8f8f8f",
  },
  addBtn: { fontSize: "1.8rem", cursor: "pointer" },
  addBtnLabel: {
    position: "absolute",
    width: 100,
    bottom: 8.5,
    left: 35,
    fontWeight: 500,
    cursor: "pointer",
  },
});
