import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import nextId from "react-id-generator";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import DetailsModal from "./DetailsModal";
import { PropertyLabel } from "../styled";

type Props = {
  events: Event[];
};

type Event = {
  level: string;
  details?: Record<string, any>;
  code?: string;
};

export default function ErrorsAndWarnings({ events }: Props) {
  const { t } = useTranslation(["tracing", "storage"]);
  const [detailsModal, setDetailsModal] = useState({
    isOpen: false,
    details: {},
  });

  return (
    <Grid item xs={12} lg={6}>
      <PropertyLabel>{t("errorsAndWarnings")}:</PropertyLabel>
      <StyledCard variant="outlined">
        <CardContent>
          <StyledList>
            {events.map(({ level, details, code }) => (
              <li key={nextId()}>
                <Grid item>
                  <PropertyLabel display="inline">{t("level")}</PropertyLabel>
                  <Typography variant="body2" display="inline">
                    {level}
                  </Typography>
                </Grid>
                <Grid item>
                  <PropertyLabel display="inline">{t("code")}</PropertyLabel>
                  <Typography variant="body2" display="inline">
                    {details?.code || code}
                  </Typography>
                </Grid>
                {details?.message && (
                  <Grid item>
                    <PropertyLabel display="inline">
                      {t("message")}
                    </PropertyLabel>
                    <Typography variant="body2" display="inline">
                      {details.message}
                    </Typography>
                  </Grid>
                )}
                {hasData(details) && (
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={() => setDetailsModal({ isOpen: true, details })}
                  >
                    {t("detailsModal.open")}
                  </Button>
                )}
              </li>
            ))}
          </StyledList>
        </CardContent>
      </StyledCard>
      {detailsModal.isOpen && (
        <DetailsModal
          isOpen={detailsModal.isOpen}
          details={detailsModal.details}
          onClose={update => setDetailsModal(update)}
        />
      )}
    </Grid>
  );
}

const hasData = (obj: object) => {
  return Object.getOwnPropertyNames(obj || {}).length > 0;
};

const StyledList = styled.ul`
  list-style-type: decimal;
  padding-inline-start: 1.2rem;
`;

const StyledCard = styled(Card)`
  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }
`;
