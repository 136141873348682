import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import LoginLayout from "components/Layouts/Login";
import DashboardLayout from "components/Layouts/Dashboard";
import PickerDemo from "components/FolderPicker/PickerDemo";
import Login from "pages/Login";
import Register from "pages/Register";
import EndRegistration from "pages/Register/endRegistration";
import ResetPassword from "pages/ResetPassword";
import ConfirmPassword from "pages/ConfirmPassword";
import Users from "pages/Users";
import Security from "pages/Security";
import Accounts from "pages/Accounts";
import Billing from "pages/Billing";
import Gateway from "pages/Gateway";
import OrgAndDomains from "pages/OrgAndDomains";
import Integrations from "pages/Integrations";
import UserActivity from "pages/UserActivity";
import Tracing from "pages/Tracing";
import SamlResponse from "pages/Login/SamlResponse";
import CloudStorageAuthResponse from "pages/CloudStorageAuthResponse/CloudStorageAuthResponse";
import LinkStorageAuth from "pages/CloudStorageAuthResponse/LinkStorageAuth";
import CopyPolicies from "pages/CopyPolicies";
import SecurityDefinitions from "pages/SecurityDefinitions";
import Datasets from "pages/Datasets";
import Variables from "pages/Variables";
import Notifications from "pages/Notifications";
import UsageReports from "pages/UsageReports";
import SecurityPolicies from "pages/SecurityPolicies";
import TemplateDefinitions from "pages/TemplateDefinitions";
import TemplatePolicies from "pages/TemplatePolicies";
import FilterPolicies from "pages/FilterPolicies";
import RulesList from "pages/Rules/List";
import ActionTypeSelectionPage from "pages/Rules/CreateOrEdit/ActionTypeSelectionPage";
import CreateOrEditRule from "pages/Rules/CreateOrEdit";
import AccountStats from "pages/Rules/AccountStats";
import NotificationSettings from "pages/NotificationSettings";
import PowerShellAuth from "components/PowerShellAuth";
import ReprocessEmails from "pages/ReprocessEmails";
import MxheroShares from "pages/MxheroShares";
import AutoLogout from "utils/AutoLogout";
import { isAuthenticated, addOriginPathInSession } from "utils/authentication";

export default function Routes() {
  return (
    <Router>
      <AutoLogout />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Switch>
          <DashboardRoute exact path="/">
            <Redirect to="/rules/list" />
          </DashboardRoute>
          <LoginRoute path="/saml-response">
            <SamlResponse />
          </LoginRoute>
          <LoginRoute path="/login">
            <Login />
          </LoginRoute>
          <LoginRoute path="/register/:id">
            <EndRegistration />
          </LoginRoute>
          <LoginRoute exact path="/register">
            <Register />
          </LoginRoute>
          <LoginRoute exact path="/reset-password">
            <ResetPassword />
          </LoginRoute>
          <LoginRoute path="/reset-password/:token">
            <ConfirmPassword />
          </LoginRoute>
          <DashboardRoute path="/rules/list">
            <RulesList />
          </DashboardRoute>
          <DashboardRoute path="/rules/create">
            <ActionTypeSelectionPage />
          </DashboardRoute>
          <DashboardRoute path="/rules/:type/create">
            <CreateOrEditRule />
          </DashboardRoute>
          <DashboardRoute path="/rules/:id/:action(edit|copy)">
            <CreateOrEditRule />
          </DashboardRoute>
          <DashboardRoute exact path="/">
            <Redirect to="/rules/list" />
          </DashboardRoute>
          <DashboardRoute path="/rules/:id/account-stats">
            <AccountStats />
          </DashboardRoute>
          <DashboardRoute path="/users">
            <Users />
          </DashboardRoute>
          <DashboardRoute path="/security">
            <Security />
          </DashboardRoute>
          <DashboardRoute path="/accounts">
            <Accounts />
          </DashboardRoute>
          <DashboardRoute path="/billing">
            <Billing />
          </DashboardRoute>
          <DashboardRoute path="/gateway">
            <Gateway />
          </DashboardRoute>
          <DashboardRoute path="/organization-and-domains">
            <OrgAndDomains />
          </DashboardRoute>
          <DashboardRoute path="/integrations">
            <Integrations />
          </DashboardRoute>
          <DashboardRoute path="/user-activity">
            <UserActivity />
          </DashboardRoute>
          <DashboardRoute path="/tracing">
            <Tracing />
          </DashboardRoute>
          <DashboardRoute path="/notifications">
            <Notifications />
          </DashboardRoute>
          <DashboardRoute path="/usage-reports">
            <UsageReports />
          </DashboardRoute>
          <DashboardRoute path="/mxhero-shares">
            <MxheroShares />
          </DashboardRoute>
          <DashboardRoute path="/copy-policies">
            <CopyPolicies />
          </DashboardRoute>
          <DashboardRoute path="/security-definitions">
            <SecurityDefinitions />
          </DashboardRoute>
          <DashboardRoute path="/(datasets|datasources)">
            <Datasets />
          </DashboardRoute>
          <DashboardRoute path="/variables">
            <Variables />
          </DashboardRoute>
          <DashboardRoute path="/security-policies">
            <SecurityPolicies />
          </DashboardRoute>
          <DashboardRoute path="/template-definitions">
            <TemplateDefinitions />
          </DashboardRoute>
          <DashboardRoute path="/template-policies">
            <TemplatePolicies />
          </DashboardRoute>
          <DashboardRoute path="/filter-policies">
            <FilterPolicies />
          </DashboardRoute>
          <DashboardRoute path="/notification-settings">
            <NotificationSettings />
          </DashboardRoute>
          {process.env.NODE_ENV === "development" && (
            <DashboardRoute path="/picker-demo">
              <PickerDemo />
            </DashboardRoute>
          )}
          <DashboardRoute path="/reprocess-emails">
            <ReprocessEmails />
          </DashboardRoute>
          <DashboardRoute path="/powershell-auth">
            <PowerShellAuth />
          </DashboardRoute>
          {/* Blank page used in cloud storage authorization popup  */}
          <Route path="/storage-auth-response">
            <CloudStorageAuthResponse />
          </Route>

          <Route path="/link-authorization/:code">
            <LinkStorageAuth />
          </Route>

          <Route path="/loading">
            <Loader />
          </Route>

          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </SnackbarProvider>
    </Router>
  );
}

function LoginRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => {
        if (isAuthenticated()) return <Redirect to="/" />;
        return <LoginLayout>{children}</LoginLayout>;
      }}
    />
  );
}

function DashboardRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isAuthenticated()) {
          addOriginPathInSession(location.pathname);
          return <Redirect to="/login" />;
        }

        return <DashboardLayout>{children}</DashboardLayout>;
      }}
    />
  );
}

export function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
      data-testid="page_loading"
    >
      <CircularProgress />
    </div>
  );
}
