import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const dateFormat = "YYYY-MM-DD";

export function now() {
  return dayjs().format(dateFormat);
}

export function lastWeek() {
  return dayjs().subtract(7, "days").format(dateFormat);
}

export function getDateLimits() {
  const date = dayjs();
  return {
    min: date.subtract(1, "year").format(dateFormat),
    max: date.format(dateFormat),
  };
}
