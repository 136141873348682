import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionProps,
  Grid,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { NotificationEvent } from "@dashboard-v3/api";
import {
  PropertyData,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "components/EmailEventDetails/styled";

import EmailDetails from "./EmailDetails";
import RuleDetails from "./RuleDetails";
import PlatformDetails from "./PlatformDetails";

interface ItemProps {
  notification: NotificationEvent;
}

export default function NotificationItem({ notification }: ItemProps) {
  const { t } = useTranslation("notifications");
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { code, createdAt, id, level, type } = notification;
  const containsDetails = type !== "STORAGE";
  const expandIcon = containsDetails ? <ExpandMoreIcon /> : null;

  const handleExpanded: AccordionProps["onChange"] = (e, expanded) => {
    setExpanded(expanded);
  };

  return (
    <ListItem disableGutters>
      <Accordion
        expanded={expanded}
        className={classes.accordion}
        onChange={handleExpanded}
      >
        <StyledAccordionSummary expandIcon={expandIcon}>
          <ListItemText
            primary={
              <>
                <PropertyLabel>
                  {new Date(createdAt).toLocaleString()}
                </PropertyLabel>
                <Grid container>
                  <Grid item lg={6}>
                    <PropertyLabel>{t("level")}:</PropertyLabel>
                    <PropertyData className={classes.data}>
                      {t(`notification.level.${level}`)}
                    </PropertyData>
                  </Grid>
                  <Grid item lg={6}>
                    <PropertyLabel>{t("type")}:</PropertyLabel>
                    <PropertyData className={classes.data}>
                      {t(`notification.type.${type}`)}
                    </PropertyData>
                  </Grid>
                </Grid>
              </>
            }
            secondary={
              <Grid container>
                <Grid item lg={6}>
                  <PropertyLabel>{t("id")}:</PropertyLabel>
                  <PropertyData className={classes.data}>{id}</PropertyData>
                </Grid>
                <Grid item lg={6}>
                  <PropertyLabel>{t("code")}:</PropertyLabel>
                  <PropertyData className={classes.data}>{code}</PropertyData>
                </Grid>
              </Grid>
            }
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {type === "MAIL" && (
            <EmailDetails
              notification={notification}
              onError={() => setExpanded(false)}
            />
          )}
          {type === "RULE" && (
            <RuleDetails
              notification={notification}
              onError={() => setExpanded(false)}
            />
          )}
          {type === "PLATFORM" && <PlatformDetails />}
        </StyledAccordionDetails>
      </Accordion>
    </ListItem>
  );
}

const PropertyLabel = ({ children }) => {
  return (
    <Typography color="textSecondary" component="span" variant="body2">
      {children}
    </Typography>
  );
};

const useStyles = makeStyles({
  accordion: { width: "100%" },
  data: {
    marginLeft: "5px",
    fontWeight: 500,
    display: "inline",
  },
});
