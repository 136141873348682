import styled from "styled-components";

export const EmailInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 30px;
  width: 100%;
`;

export const DateInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
  width: 100%;
`;
