/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { green, orange, red } from "@material-ui/core/colors";
import { Button } from "components/Forms/StyledComponents";
import useAuthPopup, {
  ResponseFail,
  wasClosedByUser,
} from "components/useAuthPopup";

type Status = "LOADING" | "ERROR" | "SUCCESS" | "CLOSED_BY_USER";

export default function LinkStorageAuth() {
  const { t } = useTranslation("components");
  const { code } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { open } = useAuthPopup();
  const [status, setStatus] = useState<Status>("LOADING");
  const [errorCode, setErrorCode] = useState("");

  async function authorizeAccount() {
    setStatus("LOADING");
    const res = await open({ url: `/storage-accounts/link/${code}` });

    if (res?.success) return setStatus("SUCCESS");

    if (wasClosedByUser(res)) return setStatus("CLOSED_BY_USER");

    const { errorCode } = res as ResponseFail;

    setStatus("ERROR");
    setErrorCode(errorCode);
  }

  useEffect(() => {
    authorizeAccount();
  }, []);

  if (status === "LOADING")
    return (
      <Dialog open={true}>
        <DialogContent
          className={classes.content}
          style={{ paddingBottom: 20 }}
        >
          <CircularProgress thickness={1.8} className={classes.loader} />
          <Typography
            className={classes.contentText}
            variant="caption"
            color="textSecondary"
            align="center"
          >
            {t("storageLinkAuthorization.loading")}
          </Typography>
        </DialogContent>
      </Dialog>
    );

  if (status === "CLOSED_BY_USER")
    return (
      <Dialog open={true}>
        <DialogContent
          className={classes.content}
          style={{ paddingBottom: 20 }}
        >
          <ReportProblemOutlinedIcon
            className={classes.icon}
            style={{ color: orange[400] }}
          />
          <Typography
            className={classes.contentText}
            variant="caption"
            color="textSecondary"
            align="center"
          >
            {t("storageLinkAuthorization.closedByUser")}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button
            size="small"
            wording={t("storageLinkAuthorization.btn.tryAgain")}
            onClick={authorizeAccount}
          />
        </DialogActions>
      </Dialog>
    );

  if (status === "ERROR")
    return (
      <Dialog open={true}>
        <DialogContent
          className={classes.content}
          style={{ paddingBottom: 20 }}
        >
          <CancelOutlinedIcon
            className={classes.icon}
            style={{ color: red[400] }}
          />
          <Typography
            className={classes.contentText}
            variant="caption"
            color="textSecondary"
            align="center"
          >
            {t(`storageLinkAuthorization.errors.${errorCode}`)}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button
            variant="outlined"
            size="small"
            wording={t("storageLinkAuthorization.btn.goToDashboard")}
            onClick={() => history.push("/")}
          />
          {errorCode === "INIT_WITH_DIFFERENT_ACCOUNTS" && (
            <Button
              size="small"
              wording={t("storageLinkAuthorization.btn.tryAgain")}
              onClick={authorizeAccount}
            />
          )}
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog open={true}>
      <DialogContent className={classes.content}>
        <CheckCircleOutlineOutlinedIcon
          className={classes.icon}
          style={{ color: green[400] }}
        />
        <Typography
          className={classes.contentText}
          variant="caption"
          color="textSecondary"
          align="center"
        >
          {t("storageLinkAuthorization.success")}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button
          size="small"
          wording={t("storageLinkAuthorization.btn.goToDashboard")}
          onClick={() => history.push("/")}
        />
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  loader: {
    "&.MuiCircularProgress-root": {
      width: "90px !important",
      height: "90px !important",
    },
    "&.MuiCircularProgress-colorPrimary": {
      color: "#009ed2",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
    paddingTop: 10,
  },
  contentText: { fontSize: "14px" },
  actionContainer: { marginTop: 10, marginBottom: 8, justifyContent: "center" },
  icon: { fontSize: 150 },
});
