import SwitchField from "components/Forms/SwitchField";
import { OptionGroup } from "components/Styled";
import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useRuleContext } from "../../context/ruleContext";
import FilterPolicyOption from "./FilterPolicyOption";

export default function ArchivingAddressOptions() {
  const { t } = useTranslation("rules");
  const { state, dispatch } = useRuleContext();
  const filter = state.rule.copyEmails.archivingAddress.filter;

  // useEffect(() => {
  //   return () => {
  //     console.log("In ArchivingAddressOptions useEffect");
  //     dispatch({
  //       type: "updateFilterTarget",
  //       payload: null,
  //     });
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleFilterBySender: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;

    dispatch({
      type: "updateFilterTarget",
      payload: checked ? { filterBySender: [{ type: "ORGANIZATION" }] } : null,
    });
  };

  return (
    <OptionGroup>
      <FilterPolicyOption />
      <SwitchField
        name="filterBySender"
        label={t("archivingFilter.filterBySender.label")}
        description={t("archivingFilter.filterBySender.desc")}
        value={!!filter?.filterBySender}
        onChange={handleFilterBySender}
      />
    </OptionGroup>
  );
}
