import { ChangeEventHandler } from "react";
import produce from "immer";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  FormHelperText,
  Box,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import SwitchField from "components/Forms/SwitchField";
import { AdvancedOptionsContainer, OptionGroup } from "components/Styled";
import { useRuleContext } from "pages/Rules/CreateOrEdit/context/ruleContext";
import isEmail from "utils/isEmail";
import { isBlank } from "utils/string";

import { Mailbox, Notifications } from "@dashboard-v3/api";
import { State } from "pages/Rules/CreateOrEdit/context/types";
import { isCopyFilesAllowed } from "pages/Rules/CreateOrEdit/context/helpers";
import { getActionState } from "pages/Rules/RuleActions/utils";

export default function AdvancedOptions({
  mailbox,
  updateMailbox,
}: MailboxOptionsProps) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const { content } = getActionState(state);

  const { email, onDone, onError } = mailbox.notifications;
  const { markProcessed, markProcessedText } = mailbox.postProcessing ?? {};

  const postProcessingAvailable =
    mailbox.provider === "GOOGLE" || mailbox.provider === "OFFICE365";

  const handleNotifications: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked, value } = e.target;
    const notifications = produce(mailbox.notifications, draft => {
      if (name === "email") {
        draft.email = value;
      } else {
        draft[name] = checked;
      }
    });

    updateMailbox({ notifications });
  };

  const handlePostProcessing: ChangeEventHandler<HTMLInputElement> = e => {
    const { name, checked, value } = e.target;

    const { postProcessing } = produce(mailbox, draft => {
      if (!draft.postProcessing) {
        draft.postProcessing = {
          markProcessed: false,
          markProcessedText: "mxHERO",
        };
      }
      if (name === "markProcessed") {
        draft.postProcessing.markProcessed = checked;
      }

      if (name === "markProcessedText") {
        draft.postProcessing.markProcessedText = value;
      }
    });

    updateMailbox({ postProcessing });
  };

  const handleCopyEmail: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;

    updateMailbox({ copyEmailFilesWhenNoAttachments: checked });
  };

  function wrongMarkProcessedText(mailbox: Mailbox) {
    const { postProcessing } = mailbox;
    return (
      postProcessing?.markProcessed &&
      isBlank(postProcessing?.markProcessedText)
    );
  }

  function someError(mailbox: Mailbox) {
    return (
      showEmailValidation(mailbox.notifications, state) ||
      wrongMarkProcessedText(mailbox)
    );
  }

  return (
    <AdvancedOptionsContainer>
      <StyledSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography>{t("advancedOptions")}</Typography>
          </Grid>
          <Grid item>
            {someError(mailbox) && (
              <Box color="error.main" pl={2} display="flex" alignItems="center">
                <Box pl={2} pr={1}>
                  {t("target.advancedOptions.error")}
                </Box>
                <Box pt="5px">
                  <ReportProblemOutlinedIcon />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </StyledSummary>
      <StyledDetails>
        {isCopyFilesAllowed(content, state.rule.actionType) && (
          <OptionGroup>
            <SwitchField
              name="copyEmailFilesWhenNoAttachments"
              label={t("mailboxSection.copyFiles.label")}
              value={!!mailbox?.copyEmailFilesWhenNoAttachments}
              onChange={handleCopyEmail}
            />
          </OptionGroup>
        )}
        <OptionGroup>
          <SwitchField
            name="onDone"
            label={t("mailboxSection.onDone.label")}
            value={onDone}
            onChange={handleNotifications}
          />
        </OptionGroup>
        <OptionGroup>
          <SwitchField
            name="onError"
            label={t("mailboxSection.onError.label")}
            value={onError}
            onChange={handleNotifications}
          />
        </OptionGroup>

        <OptionGroup>
          <FieldLabel gutterBottom variant="subtitle1">
            {t("mailboxSection.email.title")}
          </FieldLabel>
          <TextField
            name="email"
            label={t("mailboxSection.email.label")}
            variant="outlined"
            fullWidth
            disabled={!onDone && !onError}
            value={email}
            onChange={handleNotifications}
          />
          {showEmailValidation(mailbox.notifications, state) && (
            <FormHelperText error>
              {t("mailboxSection.email.error")}
            </FormHelperText>
          )}
        </OptionGroup>
        {postProcessingAvailable && (
          <OptionGroup>
            <SwitchField
              name="markProcessed"
              label={t("mailboxSection.markProcessed.label")}
              value={markProcessed}
              onChange={handlePostProcessing}
            />
            {markProcessed && (
              <>
                <TextField
                  variant="outlined"
                  name="markProcessedText"
                  label={t("mailboxSection.markProcessedText.label")}
                  style={{ marginTop: "10px" }}
                  fullWidth
                  value={markProcessedText ?? "mxHERO"}
                  onChange={handlePostProcessing}
                />
                {wrongMarkProcessedText(mailbox) && (
                  <FormHelperText error>
                    {t("mailboxSection.markProcessedText.error")}
                  </FormHelperText>
                )}
              </>
            )}
          </OptionGroup>
        )}
      </StyledDetails>
    </AdvancedOptionsContainer>
  );
}

const StyledSummary = styled(AccordionSummary)`
  padding: 0 24px;
`;

const StyledDetails = styled(AccordionDetails)`
  padding: 8px 24px 24px;
  flex-direction: column;
`;

const FieldLabel = styled(Typography)`
  font-weight: 500;
  margin-bottom: 10px;
`;

type MailboxOptionsProps = {
  mailbox: Mailbox;
  updateMailbox: (updates: Partial<Mailbox>) => void;
};

const showEmailValidation = (notifications: Notifications, state: State) => {
  const { email, onDone, onError } = notifications;
  const { valid } = state.steps.target;
  if (onDone || onError) {
    return !valid && !isEmail(email);
  }
  return false;
};
