import { v4 as keyGen } from "uuid";
import apiRequest from "utils/apiRequestWithErrorCode";
import { fetchAccounts } from "utils/api/accounts";
import { fetchGroups } from "utils/api/groups";
import isEmail from "utils/isEmail";

import { ReviewerOption } from "../types";
import { Variable } from "@dashboard-v3/api";

export async function getReviewerOptions(
  text: string
): Promise<ReviewerOption[]> {
  const textToSearch = text?.trim()?.toUpperCase();
  if (!textToSearch) return [];

  const [tags, accounts, groups, variables] = await Promise.all([
    getTags(textToSearch),
    getAccounts(textToSearch),
    getGroups(textToSearch),
    getVariables(textToSearch),
  ]);

  const options = [...tags, ...accounts, ...groups, ...variables];

  if (!options.length) {
    if (isEmail(text)) {
      options.push({ key: keyGen(), label: text, type: "EMAIL", value: text });
    }
  }

  return options;
}

export async function getAccounts(search?: string): Promise<ReviewerOption[]> {
  const accounts = await fetchAccounts({
    limit: 100000,
    partialEmailOrAlias: search,
    type: "member",
  });

  if (!search) {
    return accounts.map(({ email }) => ({
      key: keyGen(),
      label: email,
      type: "EMAIL",
      value: email,
    }));
  }

  return accounts.reduce<ReviewerOption[]>((options, { email }) => {
    if (email.toLowerCase().startsWith(search?.toLowerCase())) {
      options.push({
        key: keyGen(),
        label: email,
        type: "EMAIL",
        value: email,
      });
    }
    return options;
  }, []);
}

export async function getGroups(search?: string): Promise<ReviewerOption[]> {
  const groups = await fetchGroups(100000, 0, search);

  if (!search) {
    return groups.map(({ id, name }) => ({
      key: id,
      label: name,
      type: "EMAIL",
      value: id,
    }));
  }

  return groups.reduce((options, { id, name }) => {
    if (name.toLowerCase().startsWith(search.toLowerCase())) {
      options.push({
        key: id,
        label: name,
        type: "GROUP",
        value: id,
      });
    }

    return options;
  }, []);
}

export async function getVariables(search?: string): Promise<ReviewerOption[]> {
  const variables = await apiRequest<Variable[]>("GET", "/variables");

  if (!search) {
    return variables.map(({ id, name }) => ({
      key: id,
      type: "VARIABLE",
      label: name,
      value: id,
    }));
  }

  return variables.reduce<ReviewerOption[]>((options, { id, name }) => {
    const parsedSearch = search.toLowerCase();
    const isSameId = id === parsedSearch;

    if (name.toLowerCase().startsWith(parsedSearch) || isSameId) {
      options.push({
        key: id,
        type: "VARIABLE",
        label: name,
        value: id,
      });
    }
    return options;
  }, []);
}

export async function getTags(search?: string): Promise<ReviewerOption[]> {
  const tags = await apiRequest<string[]>("GET", "/accounts/tags");

  if (!search)
    return tags.map(t => ({
      key: keyGen(),
      type: "TAG",
      label: t,
      value: t,
    }));

  return tags.reduce<ReviewerOption[]>((options, tag) => {
    if (tag.toLowerCase().startsWith(search.toLowerCase())) {
      options.push({
        key: keyGen(),
        type: "TAG",
        label: tag,
        value: tag,
      });
    }
    return options;
  }, []);
}
