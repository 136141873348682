import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Popover } from "@material-ui/core";
import { PropertyData } from "components/EmailEventDetails/styled";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface RecipientsProps {
  values: string[];
  classes: ClassNameMap<"data" | "subject" | "accordion" | "accordionDetails">;
}

export default function Recipients({ classes, values }: RecipientsProps) {
  const { t } = useTranslation("tracing");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [first, ...others] = values;

  return (
    <PropertyData className={classes.data}>
      <>
        <>{first}</>
        <Button
          size="small"
          onClick={event => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          color="primary"
        >
          {t("more")}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClick={event => event.stopPropagation()}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            {others.map(recipient => (
              <div key={recipient}>{recipient}</div>
            ))}
          </Box>
        </Popover>
      </>
    </PropertyData>
  );
}
