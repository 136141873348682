import { State } from "../types";
import produce, { Draft } from "immer";
import {
  getArchivingAddress,
  getEmailFlow,
  getInitArchivingAddress,
  getInitEmailFlow,
  getMailbox,
  isCopyFilesAllowed,
  typePath,
  updateArchivingAddress,
  updateCloudStorage,
  updateEmailFlow,
  updateMailbox,
} from "../helpers";
import { getUser } from "utils/authentication";
import initChecks from "../initChecks";
import { Rule } from "@dashboard-v3/api";
import { getStorage, OptionTypes } from "pages/Rules/CloudStorage/utils";
import { canUseStoreByUserAccount } from "pages/Rules/CloudStorage/AdvancedOptions/UseStoreByUserAccount";
import { getActionState } from "pages/Rules/RuleActions/utils";
export default function changeTargetType(
  state: State,
  newTargetType: Rule["targetType"]
): State {
  const isRequestApproval = state.rule.actionType === "REQUEST_APPROVAL";
  if (state.rule.targetType === newTargetType) return state;

  return produce(state, draft => {
    cleanPreviousTargets(draft);

    draft.rule.targetType = newTargetType;
    draft.steps.target.done = false;

    switch (newTargetType) {
      case "EMAIL_FLOW":
        changeToEmailFlow(draft);
        break;
      case "ARCHIVING_ADDRESS":
        changeToArchivingAddress(draft);
        break;
      case "MAIL_BOX":
        changeToMailBox(draft);
        break;
    }

    if (!canUseStoreByUserAccount(draft) && !isRequestApproval) {
      updateCloudStorage(draft.rule, { allManagedUserIds: false });
    }
  });
}

function cleanPreviousTargets(draft: Draft<State>) {
  const path = typePath(draft.type, draft.rule);

  if ("emailFlow" in path) {
    delete path.emailFlow;
  }

  if ("archivingAddress" in path) {
    delete path.archivingAddress;
  }

  if ("mailbox" in path) {
    delete path.mailbox;
  }
}

function changeToMailBox(draft: Draft<State>) {
  const mailbox = getMailbox(draft.rule);
  const { content } = getActionState(draft);
  const allowCopyFiles = isCopyFilesAllowed(content, draft.rule.actionType);

  draft.steps.target.valid = false;
  draft.filters.targetFilter.valid = true;
  draft.filters.targetFilter.errors = {};

  if (
    draft.rule.actionType === "COPY_EMAILS" &&
    "saveForwardedMessages" in draft.rule.copyEmails
  ) {
    delete draft.rule.copyEmails.saveForwardedMessages;
  }

  if (!mailbox) {
    const { email: redirectAddress } = getUser();

    updateMailbox(draft.rule, {
      provider: null,
      authentication: null,
      accounts: { type: "ORGANIZATION", entities: [] },
      repeatable: false,
      filter: null,
      notifications: {
        email: redirectAddress,
        onDone: false,
        onError: true,
      },
      ...(allowCopyFiles && { copyEmailFilesWhenNoAttachments: false }),
    });
  }
}

function changeToArchivingAddress(draft: Draft<State>) {
  const archivingAddress = getArchivingAddress(draft.rule);

  draft.steps.target.valid = true;
  draft.filters.targetFilter.valid = true;
  draft.filters.targetFilter.errors = {};

  if (
    draft.rule.actionType === "COPY_EMAILS" &&
    "saveForwardedMessages" in draft.rule.copyEmails
  ) {
    delete draft.rule.copyEmails.saveForwardedMessages;
  }

  if (!archivingAddress) {
    updateArchivingAddress(draft.rule, getInitArchivingAddress());
  }

  draft.checks = initChecks(draft.rule);
}

function changeToEmailFlow(draft: Draft<State>) {
  const emailFlow = getEmailFlow(draft.rule);

  draft.steps.target.valid = true;
  draft.filters.targetFilter.valid = true;
  draft.filters.targetFilter.errors = {};

  if (!emailFlow) {
    updateEmailFlow(draft.rule, getInitEmailFlow(draft.rule));
  }

  const actionType = draft.rule.actionType;

  // Ticket https://mxhero.atlassian.net/browse/MXH-2920
  if (actionType === "REPLACE_ATTACHMENTS" || actionType === "PROTECT_EMAILS") {
    const storage = getStorage(draft.rule);
    if (!Boolean(storage?.linkSecurity?.id)) {
      storage.linkSecurity = {
        id: "OPEN_LINKS",
        type: OptionTypes.DEFINITION,
      };
    }
  }
}

export type ChangeTargetType = {
  type: "changeTargetType";
  payload: Rule["targetType"];
};
