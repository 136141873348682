import {
  ArchivingAddressFilter,
  EmailFlowFilter,
  MailboxFilter,
} from "@dashboard-v3/api";
import { State } from "../types";
import produce from "immer";
import { typePath } from "../helpers";
import validateFilter from "./validateFilters";

export function updateFilterTarget(
  state: State,
  changes: UpdateFilterTarget["payload"]
): State {
  return produce(state, draft => {
    const { targetType, actionType } = state.rule;

    const isDeleteFilters = changes === "CLEAR_FILTERS";
    const hasFilterPolicy = Boolean(draft.rule.filterPolicyId);

    if (isDeleteFilters && hasFilterPolicy) {
      delete draft.rule.filterPolicyId;
    }

    const actionTypeSection = typePath(draft.type, draft.rule);

    if (targetType === "EMAIL_FLOW" && "emailFlow" in actionTypeSection) {
      if (changes === "CLEAR_FILTERS") {
        delete actionTypeSection.emailFlow.filter;
      } else {
        actionTypeSection.emailFlow.filter = changes as EmailFlowFilter;
      }
    }

    if (actionType === "COPY_EMAILS" && targetType === "ARCHIVING_ADDRESS") {
      if (changes === "CLEAR_FILTERS") {
        delete draft.rule.copyEmails.archivingAddress.filter;
      } else {
        draft.rule.copyEmails.archivingAddress.filter =
          changes as ArchivingAddressFilter;
      }
    }

    if (targetType === "MAIL_BOX" && "mailbox" in actionTypeSection) {
      if (changes === "CLEAR_FILTERS") {
        delete actionTypeSection.mailbox.filter;
      } else {
        actionTypeSection.mailbox.filter = {
          ...actionTypeSection.mailbox.filter,
          ...(changes as MailboxFilter),
        };
      }
    }

    validateFilter("targetFilter", draft);
  });
}

export type UpdateFilterTarget = {
  type: "updateFilterTarget";
  payload:
    | EmailFlowFilter
    | ArchivingAddressFilter
    | MailboxFilter
    | "CLEAR_FILTERS"
    | null;
};
