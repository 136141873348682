import produce from "immer";
import SwitchField from "components/Forms/SwitchField";
import { OptionGroup } from "components/Styled";
import { useTranslation } from "react-i18next";
import { getContentFilter } from "./utils";
import { TypeSelectContainer } from "./Styled";
import { Checkbox, MenuItem, Typography } from "@material-ui/core";
import SelectField from "components/Forms/SelectField";
import { FieldGroupProps } from "./types";
import { useRuleContext } from "../CreateOrEdit/context/ruleContext";
import { AttachmentType } from "@dashboard-v3/api";

const attachmentTypes: AttachmentType[] = [
  "IMAGE",
  "AUDIO",
  "TEXT",
  "VIDEO",
  "APPLICATION",
];

type FilterName = "includeAttachmentTypes" | "excludeAttachmentTypes";

const dropDowns = [
  "includeAttachmentTypes",
  "excludeAttachmentTypes",
] as FilterName[];

export default function DropDownFields({
  isEnabled,
  handleEnabledFields,
  updateFilter,
}: FieldGroupProps) {
  const { t } = useTranslation("rules");
  const { state } = useRuleContext();
  const filters = getContentFilter(state);

  // Get options based on the opposite filter
  function getOptions(name: FilterName) {
    if (name === "includeAttachmentTypes") {
      return attachmentTypes.filter(
        item => !filters.excludeAttachmentTypes.includes(item)
      );
    }
    if (name === "excludeAttachmentTypes") {
      return attachmentTypes.filter(
        item => !filters.includeAttachmentTypes.includes(item)
      );
    }
  }

  const handleDropdowns = e => {
    const { name, value } = e.target;
    const update = produce(filters, draft => {
      draft[name] = value;
    });
    updateFilter(update);
  };

  function menuTitle(name: FilterName, t: (string) => string) {
    return getOptions(name).length === 0
      ? t(`actionFilters.${name}.menuTitleEmpty`)
      : t(`actionFilters.${name}.menuTitle`);
  }

  return (
    <>
      {dropDowns.map(name => (
        <OptionGroup key={name}>
          <SwitchField
            name={name}
            label={t(`actionFilters.${name}.label`)}
            description={t(`actionFilters.${name}.desc`)}
            onChange={handleEnabledFields}
            value={isEnabled(name)}
            disabled={!!filters?.includeZipWithPassword}
          />
          {isEnabled(name) && (
            <TypeSelectContainer>
              <Typography>{t(`actionFilters.${name}.title`)}</Typography>
              <SelectField
                name={name}
                label={t("actionFilters.attachmentTypeLabel")}
                multiple
                menuTitle={menuTitle(name, t)}
                options={getOptions(name)}
                onChange={handleDropdowns}
                value={filters[name]}
                renderOption={attachmentType => (
                  <MenuItem key={attachmentType} value={attachmentType}>
                    <Checkbox
                      color="primary"
                      checked={(filters[name] as string[]).includes(
                        attachmentType
                      )}
                    />
                    {t(`actionFilters.attachmentType.${attachmentType}`)}
                  </MenuItem>
                )}
                renderValue={values =>
                  listValuesToLabels(values as AttachmentType[], t)
                }
              />
            </TypeSelectContainer>
          )}
        </OptionGroup>
      ))}
    </>
  );
}

function listValuesToLabels(values: AttachmentType[], t: (string) => string) {
  return values
    .map(attachmentType => t(`actionFilters.attachmentType.${attachmentType}`))
    .join(", ");
}
